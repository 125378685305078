import React from 'react';

const Card = ({ Icon, title, text }) => {
  return (
    <div className='p-4 lg:w-96 shadow-lg rounded-md flex flex-col items-center hover:-translate-y-2 hover:cursor-pointer transition-all bg-teal-50 dark:bg-blue-950/10 dark:border-2 md:h-[265px]'>
      <div className='text-6xl text-red-800 pb-4 dark:text-yellow-400'>{Icon && <Icon />}</div>
      <h3 className='text-2xl text-left mb-2 dark:text-white text-gray-600 font-semibold'>
        {title}
      </h3>
      <p className='text-lg text-slate-500 leading-7 text-center dark:text-white'>{text}</p>
    </div>
  );
};

export default Card;
