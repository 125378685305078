import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineArrowUp } from 'react-icons/ai';
import { BsFillSunFill, BsFillMoonFill } from 'react-icons/bs';

import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  const [theme, setTheme] = useState(localStorage ? localStorage.getItem('theme') : null);

  const LightTheme = () => {
    setTheme('light');
  };
  const DarkTheme = () => {
    setTheme('dark');
  };
  useEffect(() => {
    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      localStorage.setItem('theme', 'dark');
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }
  }, []);

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else if (theme === 'light') {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    } else {
      localStorage.removeItem('theme');
    }
  }, [theme]);
  return (
    <div>
      <div className=' max-w-7xl mx-auto p-6 grid xl:grid-cols-[1fr_1fr_1fr_1fr] lg:grid-cols-[1fr_1fr_1fr] md:grid-cols-[1fr_1fr]'>
        <div className=' p-4'>
          <h3 className='dark:text-yellow-400 font-bold text-xl mb-3 text-teal-500'>
            Cyn-Blitz Global Limited
          </h3>
          <p className='text-base text-gray-50 mb-2'>
            #10 Glory Land Estate, Rumunduru, Oroigwe, Port Harcourt,Rivers state.
          </p>
          <p className='text-base text-gray-50 mb-2'>Phone1:+2348064668185</p>
          <p className='text-base text-gray-50 mb-2'>Phone2:+2349045894311</p>
          <p className='text-base text-gray-50 mb-2'>Email: info@cyn-blitz.com</p>
          <div className='flex mt-2 gap-x-2'>
            <Link to=''>
              <FaFacebookF className='text-3xl rounded-sm cursor-pointer hover:bg-teal-700 bg-teal-800 text-white p-1 dark:bg-yellow-400' />
            </Link>
            <Link to=''>
              <FaTwitter className='text-3xl rounded-sm cursor-pointer hover:bg-teal-800 bg-teal-700 text-white p-1 dark:bg-yellow-400' />
            </Link>
            <Link to=''>
              <FaInstagram className='text-3xl rounded-sm cursor-pointer hover:bg-teal-800 bg-teal-700 text-white p-1 dark:bg-yellow-400' />
            </Link>
          </div>
        </div>
        <div className=' p-4'>
          <h3 className='font-bold text-xl mb-3 text-teal-500 dark:text-yellow-400'>
            Useful Links
          </h3>
          <Link
            to='/'
            className='block hover:text-cyan-300  mb-2  font-semibold text-gray-50 w-1/2'
          >
            Home
          </Link>
          <Link
            to='/about'
            className='block hover:text-cyan-300  mb-2  font-semibold text-gray-50 w-1/2'
          >
            About
          </Link>
          <Link
            to='/services'
            className='block hover:text-cyan-300  mb-2  font-semibold text-gray-50 w-1/2'
          >
            Services
          </Link>
          <Link
            to='/contact'
            className='block hover:text-cyan-300  mb-2  font-semibold text-gray-50 w-1/2'
          >
            Contact Us
          </Link>
        </div>
        <div className=' p-4'>
          <h3 className='font-bold text-xl mb-3 text-teal-500 dark:text-yellow-400'>
            Our Services
          </h3>
          <p className='mb-2 font-semibold text-gray-50'>Inspections</p>
          <p className='mb-2 font-semibold text-gray-50'>Maintainance</p>
          <p className='mb-2 font-semibold text-gray-50'>Consultation</p>
          <p className='mb-2 font-semibold text-gray-50'>Training</p>
          <p className='mb-2 font-semibold text-gray-50'>Welding </p>
          <Link
            to='/services'
            className='bg-teal-800 dark:bg-yellow-400 font-semibold px-3 py-2 inline-block rounded-sm text-white hover:bg-teal-700 transition'
          >
            see more
          </Link>
        </div>
        <div className=' p-4'>
          <h3 className='font-bold text-xl mb-3 text-teal-500 dark:text-yellow-400'>Theme</h3>

          <div className='flex gap-x-4 items-center text-gray-50'>
            <BsFillMoonFill
              className='cursor-pointer text-xl hover:text-blue-500'
              onClick={DarkTheme}
            />
            <BsFillSunFill
              className='cursor-pointer text-2xl hover:text-blue-500'
              onClick={LightTheme}
            />
          </div>
        </div>
      </div>
      <div className='text-center dark:bg-transparent bg-teal-850/40 p-2 border-t-[1px] text-white'>
        <p className='text-center text-sm'>
          {' '}
          &copy; Copyright{' '}
          <span className='font-extrabold text-gray-100'>Cyn-blitz Global Limited</span> {''}
          {new Date().getFullYear()}. All Rights Reserved
        </p>
        <p className='text-center text-sm font-[cursive] font-extralight mt-2 pb-3'>
          Designed by nedbleezy<span className='text-red-400'>Tech</span>{' '}
        </p>

        <AiOutlineArrowUp
          className='text-4xl dark:bg-yellow-400 bg-teal-800 cursor-pointer  text-white shadow-xl rounded-sm fixed bottom-5 left-[85%]   p-1 animate-pulse'
          onClick={() => (document.documentElement.scrollTop = 0)}
        />
      </div>
    </div>
  );
};

export default Footer;
