import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const CarouselT = () => {
  return (
    <div className='relative -z-10'>
      <Carousel
        autoPlay
        interval={5000}
        infiniteLoop
        swipeable
        transitionTime={8000}
        showThumbs={false}
        animationHandler='fade'
        showStatus={false}
        renderIndicator={false}
        dynamicHeight={false}
      >
        <div>
          <img src='img/slider-1.jpg' alt='' className=' object-cover' />
          <p className='xl:text-4xl lg:text-3xl md:text-2xl  absolute top-10  text-white font-extrabold left-10 text-justify'>
            Welcome to <br /> Cyn-blitz global limited
          </p>
        </div>
        <div>
          <img src='img/slider-2.jpg' alt='' className=' object-cover' />
          <p className='xl:text-4xl lg:text-3xl md:text-2xl   absolute   text-justify  text-white font-extrabold bottom-10 left-10'>
            We are Outstanding
          </p>
        </div>
        <div>
          <img src='img/slider-3.jpg' alt='' className=' object-cover' />
          <p className='xl:text-4xl lg:text-3xl md:text-2xl   absolute top-10  text-white font-extrabold right-10 text-justify'>
            We are the professionals
          </p>
        </div>
      </Carousel>
      <div className=' absolute top-0 right-0 left-0 bg-blue-400 bottom-0 h-full opacity-25'></div>
    </div>
  );
};

export default CarouselT;
