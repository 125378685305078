import React from 'react';
import TeamCard from './TeamCard';
import Heading from '../../components/heading/Heading';

const Team = () => {
  return (
    <div className='max-w-7xl mx-auto  mt-3' id='team'>
      <Heading title='Our Team' />
      <div className='  mb-4 space-y-3 grid  lg:grid-cols-[1fr_1fr_1fr] xl:grid-cols-[1fr_1fr_1fr_1fr] md:grid-cols-[1fr_1fr]'>
        <div data-aos='fade-up' data-aos-duration='2000'>
          <TeamCard img='img/Worker-1.jpg' name='Eddidiong Thomas' title='CHIEF ENGINEER' />
        </div>
        <div data-aos='fade-up' data-aos-duration='2000'>
          <TeamCard img='img/Worker-2.jpg' name='Dora White' title='ADMIN' />
        </div>
        <div data-aos='fade-up' data-aos-duration='2000'>
          <TeamCard img='img/Worker-3.jpg' name='Cyril Godwin' title='SUPERVISOR' />
        </div>
        <div data-aos='fade-up' data-aos-duration='2000'>
          <TeamCard img='img/Worker-4.jpg' name='Okere Chinedum' title='RIGGER' />
        </div>
      </div>
    </div>
  );
};

export default Team;
