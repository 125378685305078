import React from 'react';

import { Route, Routes } from 'react-router-dom';
import About from '../../pages/About';
import Services from '../../pages/Services';

import Contact from '../../pages/Contact';
import Home from '../../pages/Home';
import Footer from '../footer/Footer';
import Nav from '../nav/Nav';

const Layout = () => {
  return (
    <div className='grid grid-cols-[1fr] grid-rows-[auto_1fr_auto] w-full h-full '>
      <header className='bg-blue-950 drop-shadow-lg z-[700]'>
        <nav className='max-w-7xl mx-auto '>
          <Nav />
        </nav>
      </header>
      <main>
        <Routes>
          <Route path='about' element={<About />} />
          <Route path='services' element={<Services />} />
          <Route path='contact' element={<Contact />} />
          <Route path='/' element={<Home />} />
        </Routes>
      </main>

      <footer className='bg-blue-950 shadow rounded-tr-[5rem] dark:bg-slate-900 dark:shadow-xl dark:border-t-2 dark:border-yellow-500 mt-3'>
        <Footer />
      </footer>
    </div>
  );
};

export default Layout;
