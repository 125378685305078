import React from 'react';
import CarouselT from '../components/carousel/CarouselT';
import ChooseUs from '../components/chooseus/ChooseUs';

import Aim from '../components/aim/Aim';
import Count from '../components/countUp/Count';

import Services from './services/Services';
import Gallery from '../components/gallery/Gallery';

const Home = () => {
  return (
    <div>
      <CarouselT />
      <ChooseUs />

      <Aim />

      <Count />

      <Services />
      <Gallery />
    </div>
  );
};

export default Home;
