import React from 'react';
import Departments from '../components/departments/Departments';

const Services = () => {
  return (
    <div>
      <Departments />
    </div>
  );
};

export default Services;
