import React from 'react';
import Card from '../services/Card';

import { BiBraille, BiRocket, BiAnchor } from 'react-icons/bi';
import { TbArrowRotaryRight } from 'react-icons/tb';
import { SiConsul } from 'react-icons/si';
import { GiScrewdriver } from 'react-icons/gi';

import Heading from '../../components/heading/Heading';

const Services = () => {
  return (
    <div>
      <Heading title='Our Services' />
      <div>
        <p className='text-lg text-gray-600 font-serif  p-4 mb-5 first-letter:text-3xl first-letter:text-teal-800 text-center'>
          Cyn-blitz global limited provides the following Outstanding services:
        </p>
        <div className='grid md:grid-cols-[1fr_1fr] xl:grid-cols-[1fr_1fr_1fr] gap-4 p-4'>
          <Card Icon={BiRocket} heading='Inspections' />
          <Card Icon={BiBraille} heading='Welding & Fabrication' />
          <Card Icon={BiAnchor} heading='Trainings' />
          <Card Icon={SiConsul} heading='Consultations' />
          <Card Icon={GiScrewdriver} heading='Maintainance' />
          <Card Icon={TbArrowRotaryRight} heading='Rig acceptance survey audit' />
        </div>
      </div>
    </div>
  );
};

export default Services;
