import React from 'react';
import { FcSupport } from 'react-icons/fc';
import { GiSteeringWheel } from 'react-icons/gi';
import { Link } from 'react-router-dom';

const Aim = () => {
  return (
    <div className='max-w-7xl mx-auto grid md:grid-cols-[1fr_1fr] gap-4 my-4  dark:text-white '>
      <section
        className=' p-4  rounded-tr-3xl dark:bg-slate-900 '
        data-aos='fade-up'
        data-aos-duration='2000'
      >
        <h3 className='dark:text-white text-3xl p-2 mb-3 font-bold text-slate-700'>Our Aim</h3>
        <p className='text-lg'>
          Our objective is to provide our clients with an "I am satisfied” experience when we are
          chosen to execute their projects. Our emphasis on clear communication and follow-through
          procedures ensures that client's objectives are top priority in the planning and execution
          of all our processes.
        </p>
        <Link
          to='/about'
          className='mt-4 bg-teal-800 px-4 py-2 inline-block rounded-md shadow-md text-gray-50 text-lg font-semibold hover:bg-teal-600 transition'
        >
          More about Us
        </Link>
      </section>
      <section className=' p-4 space-y-6  rounded-tl-3xl '>
        <div
          className='flex gap-x-4 0md:mt-4  shadow p-2 dark:bg-slate-900'
          data-aos='fade-up'
          data-aos-duration='2000'
        >
          <div className='  rounded-full'>
            <GiSteeringWheel className='text-7xl text-gray-600 dark:text-teal-800' />
          </div>
          <div>
            <h3 className='dark:text-white text-3xl p-2 mb-3 font-bold text-slate-700'>
              Our Mission
            </h3>
            <p className='text-lg'>
              To deliver engineering excellence. We do this by engaging everyday on a quest to
              improve technological advancement, safety and human civilization through hard work,
              dedication and commitment to industry standards and best practices.
            </p>
          </div>
        </div>
        <div
          className='flex gap-x-4 0md:mt-4  shadow p-2 dark:bg-slate-900'
          data-aos='fade-up'
          data-aos-duration='2000'
        >
          <div className=' rounded-full'>
            <FcSupport className='text-7xl dark:bg-teal-800  text-white' />
          </div>

          <div>
            <h3 className='dark:text-white text-3xl p-2 mb-3 font-bold text-slate-700'>Our Goal</h3>
            <p className='text-lg'>
              To deliver engineering excellence. We do this by engaging everyday on a quest to
              improve technological advancement, safety and human civilization through hard work,
              dedication and commitment to industry standards and best practices.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Aim;
