import React from 'react';

const Heading = ({ title }) => {
  return (
    <div className='grid place-items-center my-10'>
      <h1 className='text-4xl mb-1 font-serif p-2 text-slate-600 font-bold dark:text-slate-800 tracking-wider'>
        {title}
      </h1>
      <div className='flex justify-center items-center'>
        <div className='w-10 h-[1.2px] bg-slate-600'></div>
        <div className='w-10 h-[1.9px] bg-yellow-400'></div>
        <div className='w-10 h-[1.2px] bg-slate-600'></div>
      </div>
    </div>
  );
};

export default Heading;
