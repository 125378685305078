import { useLocation } from 'react-router-dom';

import { useEffect } from 'react';
import Layout from './components/layout/Layout';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }, [pathname]);
  return (
    <div>
      <Layout />
    </div>
  );
};

export default App;
