import React from 'react';

const TeamCard = ({ img, name, title }) => {
  return (
    <div className=' shadow-lg relative mx-6 mb-7 rounded-2xl bg-white lg:max-w-[400px] lg:h-[320px] dark:bg-slate-300'>
      <div>
        <div className='group cursor-pointer bg-blue-950 grid place-items-center p-4 rounded-tl-[3rem]  rounded-xl rounded-br-none '>
          <div className='h-44 w-44 rounded-full bg-yellow-500 ring-8 ring-yellow-700 grid place-items-center'>
            <img
              src={img}
              alt=''
              className='group-hover:scale-110 transition duration-500 w-40 h-40 rounded-full object-cover object-top'
            />
          </div>
        </div>
        <div className=' bg-transparent font-serif  drop-shadow-2xl text-center py-2 rounded-2xl  text-gray-900  rounded-tr-[3rem]'>
          <p className='px-1 text-lg text-gray-800 font-semibold uppercase'>{name}</p>
          <p className='px-2 text-base font-bold text-blue-950'>{title}</p>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
