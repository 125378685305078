import React from 'react';

import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Heading from '../heading/Heading';

const images = [
  {
    original: '/assets/v7.jpeg',
  },
  {
    original: '/assets/e1.jpg',
  },
  {
    original: '/assets/e2.jpg',
  },
  {
    original: '/assets/fab1.jpg',
  },
  {
    original: '/assets/v1.jpg',
  },
  {
    original: '/assets/v2.jpg',
  },
  {
    original: '/assets/v3.jpg',
  },
  {
    original: '/assets/v4.jpg',
  },
  {
    original: '/assets/v5.jpg',
  },
  {
    original: '/assets/v6.jpg',
  },
  {
    original: '/assets/v8.jpg',
  },
  {
    original: '/assets/v9.jpg',
  },
  {
    original: '/assets/v10.jpg',
  },
  {
    original: '/assets/v11.jpg',
  },
  {
    original: '/assets/v12.jpg',
  },
  {
    original: '/assets/b1.jpg',
  },
  {
    original: '/assets/b2.jpg',
  },
  {
    original: '/assets/b3.jpg',
  },
  {
    original: '/assets/b4.jpg',
  },
  {
    original: '/assets/b5.jpg',
  },
  {
    original: '/assets/b6.jpg',
  },
  {
    original: '/assets/b7.jpg',
  },
  {
    original: '/assets/b8.jpg',
  },
  {
    original: '/assets/b9.jpg',
  },
  {
    original: '/assets/b10.jpg',
  },
  {
    original: '/assets/b11.jpg',
  },
  {
    original: '/assets/b12.jpg',
  },
  {
    original: '/assets/b13.jpg',
  },
  {
    original: '/assets/b14.jpg',
  },
  {
    original: '/assets/b14.jpg',
  },
  {
    original: '/assets/b15.jpg',
  },
  {
    original: '/assets/b16.jpg',
  },
  {
    original: '/assets/b17.jpg',
  },
  {
    original: '/assets/b18.jpg',
  },
  {
    original: '/assets/b19.jpg',
  },
  {
    original: '/assets/b20.jpg',
  },
  {
    original: '/assets/b21.jpg',
  },
  {
    original: '/assets/b22.jpg',
  },
  {
    original: '/assets/b23.jpg',
  },
  {
    original: '/assets/b24.jpg',
  },
  {
    original: '/assets/b25.jpg',
  },
  {
    original: '/assets/b26.jpg',
  },
  {
    original: '/assets/b27.jpg',
  },
  {
    original: '/assets/b28.jpg',
  },
  {
    original: '/assets/b29.jpg',
  },
];

const Gallery = () => {
  return (
    <div className=' max-w-7xl mx-auto dark:bg-slate-900 py-7 '>
      <Heading title='Our Gallery' />

      <div>
        <ImageGallery
          items={images}
          autoPlay={true}
          slideInterval={5000}
          renderLeftNav={() => null}
          renderRightNav={() => null}
          showPlayButton={false}
          showThumbnails={false}
        />
      </div>
    </div>
  );
};

export default Gallery;
