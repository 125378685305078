import React from 'react';
import Card from './Card';
import { GiFactory } from 'react-icons/gi';
import { HiCubeTransparent } from 'react-icons/hi';
import { BsFillGearFill } from 'react-icons/bs';
import { BsGearWideConnected } from 'react-icons/bs';

const ChooseUs = () => {
  return (
    <div className='dark:text-white  shadow dark:bg-slate-900 -z-0'>
      <div className='max-w-7xl mx-auto p-6 py-8'>
        <h1
          className='text-4xl  my-4 font-bold text-gray-600 dark:text-white'
          data-aos='fade-right'
          data-aos-duration='2000'
        >
          Why Choose Us
        </h1>
        <p className='p-2 my-2 text-lg'>We are ISO 9001:2015 certified!</p>
        <div className='grid  xl:grid-cols-[1fr_1fr_1fr] md:grid-cols-[1fr_1fr] gap-2 mt-4 '>
          <div data-aos='fade-up' data-aos-duration='2000'>
            <Card
              Icon={BsGearWideConnected}
              title='Professional Liability'
              text='With meticulous care, our engineering prowess guards against errors, ensuring professional liability remains an uncharted realm.'
            />
          </div>

          <div data-aos='fade-up' data-aos-duration='2000'>
            <Card
              Icon={HiCubeTransparent}
              title='Dedicated To Clients'
              text='We are committed to building a sustainable future by fostering a collaborative spirit.'
            />
          </div>

          <div data-aos='fade-up' data-aos-duration='2000'>
            <Card
              Icon={BsFillGearFill}
              title='Outstanding Services'
              text='Precision and innovation intertwine, sculpting dreams into reality. Our engineering mastery propels us beyond excellence.'
            />
          </div>

          <div data-aos='fade-up' data-aos-duration='2000'>
            <Card
              Icon={GiFactory}
              title='Clients Satisfaction'
              text='Client smiles, their satisfaction held paramount; our engineering prowess ensures their dreams exceed expectations.'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseUs;
