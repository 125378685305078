import React from 'react';
import CountUp from 'react-countup';

const DcountUp = ({ end, bgcolor, Icon, title }) => {
  return (
    <div
      className={` ${bgcolor} p-5  shadow-lg  text-center font-serif font-bold text-gray-50 mt-5 0md:mt-2 relative rounded-md drop-shadow-2xl hover:-translate-y-2 duration-200 cursor-pointer`}
    >
      {Icon && (
        <Icon className='absolute top-0 left-1/2 translate-x-[-50%] translate-y-[-50%]   p-3 rounded-full cursor-pointer hover:bg-red-700 text-7xl mb-3' />
      )}

      <h3 className='mt-3'>{title}</h3>
      <p className='text-3xl'>
        <CountUp end={end} duration={3} scrollSpyDelay={2} enableScrollSpy />
      </p>
    </div>
  );
};

export default DcountUp;
