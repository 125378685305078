import React from 'react';
import DcountUp from './DcountUp';
import { FaAward } from 'react-icons/fa';
import { GiLightProjector } from 'react-icons/gi';
import { BiCuboid } from 'react-icons/bi';
import { BsFillPeopleFill } from 'react-icons/bs';

const Count = () => {
  return (
    <div className='gap-4 grid dark:bg-slate-800 p-4  md:grid-cols-[1fr_1fr]  lg:grid-cols-[1fr_1fr_1fr] xl:grid-cols-[1fr_1fr_1fr_1fr] bg-slate-500 my-4  max-w-7xl mx-auto'>
      <DcountUp Icon={BsFillPeopleFill} end={35} title='EMPLOYEES' bgcolor='bg-teal-600' />
      <DcountUp Icon={FaAward} end={20} title='PARTNERS WORLDWIDE' bgcolor='bg-teal-300' />
      <DcountUp Icon={BiCuboid} end={10} title='YEAR OF EXPERIENCE' bgcolor='bg-yellow-600' />
      <DcountUp Icon={GiLightProjector} end={30} title='PROJECTS COMPLETED' bgcolor='bg-blue-500' />
    </div>
  );
};

export default Count;
