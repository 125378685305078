import React from 'react';
import { FcSupport } from 'react-icons/fc';
import { MdVerifiedUser } from 'react-icons/md';
import { GiSteeringWheel } from 'react-icons/gi';
import Heading from '../components/heading/Heading';
import Team from '../components/team/Team';

const About = () => {
  return (
    <div className='dark:bg-yellow-600'>
      <div
        className='dark:bg-slate-900 max-w-7xl mx-auto'
        data-aos='fade-up'
        data-aos-duration='2000 '
      >
        <Heading title='About Us' />

        <p className='p-6 text-lg text-gray-600 dark:text-white'>
          Cyn-Blitz is an engineering company which was incorporated in 2019. Our quality and
          professional services has given us an edge enabling us to reach where we are today. The
          teams vast experience enabled us to, identify the exact need, mobilize the appropriate
          resources and manage projects in a uniquely professional and efficient manner and deliver
          the most suitable solution
        </p>
      </div>

      <div className='max-w-7xl mx-auto grid md:grid-cols-[1fr_1fr] gap-4 my-4  dark:text-white '>
        <section
          className=' p-4  rounded-tr-3xl dark:bg-slate-900'
          data-aos='fade-up'
          data-aos-duration='2000'
        >
          <h3 className='dark:text-white text-3xl p-2 mb-3 font-bold text-slate-700'>
            We are the expart on this field, better solution since 2019.
          </h3>
          <p className='text-lg'>
            We understand the importance of innovation and professionalism that's why here in
            Cyn-Blitz, we provide innovative solutions to construction, engineering and industrial
            sectors, enabling them to achieve greater efficiencies and ultimately increase their
            profitability. By specializing in the development of skilled professionals and
            organizational culture. In every single project we undertake we are dedicated to
            providing our clients with quality results and highly effective safe work practices that
            brings peace of mind. We also have a real commitment to sustainability in all areas of
            our operation
          </p>
          <p className='text-lg mt-4'>
            Our clients tell us that one of the best things about working with us is that we adapt
            to the business needs and deliver on time while providing cost effective solutions,
            meaning we can offer value and services at any point. Tell us what you need, and we'll
            respond accordingly. That's our guarantee.
          </p>
        </section>
        <section
          className=' p-4 space-y-6  rounded-tl-3xl dark:bg-slate-900'
          data-aos='fade-up'
          data-aos-duration='2000'
        >
          <div className='flex gap-x-4 0md:mt-4  shadow p-4'>
            <div className='  rounded-full'>
              <GiSteeringWheel className='text-7xl text-gray-600 dark:text-yellow-400' />
            </div>
            <div>
              <h3 className='dark:text-white text-3xl p-2 mb-3 font-bold text-slate-700'>
                Our Core Values
              </h3>
              <p className='text-lg'>
                We believe in Safety first, Communication, Excellence, Innovation and Intergrity
                (CHEII). That is what we stand for as our core values.
              </p>
            </div>
          </div>
          <div className='flex gap-x-4 0md:mt-4  shadow p-4'>
            <div className='  rounded-full'>
              <FcSupport className='text-7xl dark:bg-yellow-400 text-white' />
            </div>

            <div>
              <h3 className='dark:text-white text-3xl p-2 mb-3 font-bold text-slate-700'>
                Dedicated To Our Clients
              </h3>
              <p className='text-lg'>
                Our clients satisfaction is our delight. We leave a touch of excellence in all our
                projects.
              </p>
            </div>
          </div>
          <div className='flex gap-x-4 0md:mt-4  shadow p-4'>
            <div className='  rounded-full'>
              <MdVerifiedUser className='text-7xl dark:bg-yellow-400 text-gray-500' />
            </div>

            <div>
              <h3 className='dark:text-white text-3xl p-2 mb-3 font-bold text-slate-700'>
                Outstanding Services
              </h3>
              <p className='text-lg'>
                We are committed to building a sustainable future by fostering a collaborative
                spirit.
              </p>
            </div>
          </div>
        </section>
      </div>

      <div>
        <Team />
      </div>
    </div>
  );
};

export default About;
