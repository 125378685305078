import React, { useState } from 'react';
import { ImLocation2 } from 'react-icons/im';
import { BiPhoneCall } from 'react-icons/bi';
import { MdEmail } from 'react-icons/md';
import Heading from '../components/heading/Heading';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const EMAILJS_SERVICE_ID = 'service_rdsxi8q';
  const EMAILJS_Public_Key = 'njIqHykim-zG08iM1';
  const EMAILJS_template = 'template_5s40f8x';

  const [form, setForm] = useState({
    Name: '',
    Email: '',
    Subject: '',
    Message: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(EMAILJS_SERVICE_ID, EMAILJS_template, form, EMAILJS_Public_Key)
      .then((result) => {
        e.target.reset();
        toast.success('Message Sent!', result.text, {
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      })
      .catch((err) => {
        e.target.reset();
        toast.error('An error occurred, Please try again', err.text, {
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      });
  };

  return (
    <div className='dark:bg-slate-900'>
      <div className='mb-5 pt-4 max-w-7xl mx-auto'>
        <Heading title='Contact Us' />
        <div className=' p-6 md:flex gap-x-6'>
          <div className='md:w-1/2 '>
            {/* ADDRESS  */}
            <div data-aos='fade-right' data-aos-duration='2000' className='contactbox  bg-gray-100'>
              <div className='grid place-items-center'>
                <ImLocation2 className='icon' />
              </div>

              <h2 className='text-3xl py-2 font-serif font-semibold text-gray-500 dark:text-gray-200'>
                Our Address
              </h2>
              <p className='text-gray-700 text-lg dark:text-gray-100'>
                #10 Glory Land Estate, Rumunduru, Oroigwe, Port Harcourt,Rivers State.
              </p>
            </div>

            {/* CALL US __ EMAIL US */}

            <div className='md:flex gap-x-4  '>
              {/* CALL US */}
              <div
                data-aos='fade-up'
                data-aos-duration='2000'
                className='contactbox  md:w-1/2  bg-gray-100'
              >
                <div className='grid place-items-center'>
                  <BiPhoneCall className='icon ' />
                </div>

                <h2 className='text-3xl py-2 font-serif font-semibold text-gray-500 dark:text-gray-200'>
                  Call Us
                </h2>
                <p className='text-gray-700 text-lg dark:text-gray-100'>+2349045894311</p>
                <p className='text-gray-700 text-lg dark:text-gray-100'>+2348064668185</p>
              </div>

              {/* EMAIL US */}
              <div
                data-aos='fade-up'
                data-aos-duration='2100'
                className='contactbox   md:w-1/2 bg-gray-100'
              >
                <div className='grid place-items-center'>
                  <MdEmail className='icon' />
                </div>

                <h2 className='text-3xl py-2 font-serif font-semibold text-gray-500 dark:text-gray-200'>
                  Email Us
                </h2>
                <p className='text-gray-700  dark:text-gray-100'>info@cyn-blitz.com</p>
                <p className='text-gray-700  dark:text-gray-100'>blitzcynergy@gmail.com</p>
              </div>
            </div>
          </div>
          <form
            className='contactbox border-y-2 w-full md:w-1/2 flex flex-col justify-between 0md:space-y-3  bg-gray-100'
            data-aos='fade-right'
            data-aos-duration='2300'
            onSubmit={handleSubmit}
          >
            <div className='md:flex gap-4 '>
              <input
                type='text'
                placeholder='Your Name'
                required
                className='finput '
                name='Name'
                onChange={(e) => setForm({ ...form, Name: e.target.value })}
              />
              <input
                type='email'
                placeholder='Your Email'
                required
                className='finput'
                name='Email'
                onChange={(e) => setForm({ ...form, Email: e.target.value })}
              />
            </div>

            <input
              type='text'
              placeholder='subject'
              required
              className='finput '
              onChange={(e) => setForm({ ...form, Subject: e.target.value })}
              name='Subject'
            />
            <textarea
              placeholder='Message'
              required
              rows={4}
              onChange={(e) => setForm({ ...form, Message: e.target.value })}
              name='Message'
            />
            <div className='flex justify-center '>
              <button
                type='submit'
                className='bg-yellow-500 text-lg px-4 uppercase font-semibold text-white font-serif py-2 rounded-md block hover:bg-orange-500 transition'
              >
                submit
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className='relative text-right h-96 w-full'>
        <div className='overflow-hidden bg-none h-full w-full '>
          <iframe
            width='100%'
            title='map'
            height='100%'
            id='gmap_canvas'
            src='https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q= Rumunduru, Oroigwe,&amp;t=&amp;z=10&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'
          ></iframe>

          <br />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Contact;
