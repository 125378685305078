import React from 'react';
import { Link } from 'react-router-dom';

const Card = ({ Icon, heading }) => {
  return (
    <div data-aos='fade-up' data-aos-duration='2000'>
      <div className='bg-white dark:bg-slate-900  p-4 text-center font-serif text-gray-600 space-y-4 hover:shadow-2xl cursor-pointer transition duration-200 hover:-translate-y-2'>
        <div className='grid place-items-center'>
          {Icon && (
            <Icon className='text-center text-5xl cursor-pointer text-white rounded-full p-2 ring-4 ring-white drop-shadow-2xl bg-teal-800 shadow-2xl dark:bg-yellow-400' />
          )}
        </div>

        <h4 className='text-2xl dark:text-slate-300 font-semibold'>{heading}</h4>
        <div className='grid place-items-center'>
          <hr className='w-20 p-[.8px] bg-teal-800 dark:bg-yellow-400' />
        </div>

        <div>
          <Link
            to='/services'
            className='bg-teal-800 px-4 py-2 rounded-md text-white text-lg dark:bg-yellow-400'
          >
            {' '}
            learn more
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Card;
