import React, { useState } from 'react';

const Departments = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (e, id) => {
    setActiveTab(id);
  };
  return (
    <div className='max-w-7xl mx-auto dark:bg-slate-900'>
      <h1 className='dark:text-gray-100 text-4xl text-gray-800 font-serif font-extrabold text-center p-5'>
        Our Services
      </h1>
      <div className='grid place-items-center'>
        <hr className='w-20  p-[.8px] bg-cyan-400 mb-4' />
      </div>
      <p className='text-lg md:text-center dark:text-slate-400 text-gray-700 p-5 font-serif text-center'>
        All our services are led by a team of renowned Engineers and equipped with cutting-edge
        technologies
      </p>
      <div className='my-4 p-6 md:flex gap-x-4 '>
        <div>
          <ul className='box-border'>
            {[
              {
                id: 1,
                name: 'INSPECTIONS',
              },
              {
                id: 2,
                name: 'ENGINEERING, PROCUREMENTS',
              },
              {
                id: 3,
                name: 'LOGISTICS',
              },
              {
                id: 4,
                name: 'CALIBRATION & CERTIFICATION SERVICES',
              },
              {
                id: 5,
                name: 'NON-DESTRUCTIVE TESTING',
              },
              {
                id: 6,
                name: 'PRESSURE TESTING AND LEAK TEST',
              },
              {
                id: 7,
                name: 'PERSONNEL TRAINING',
              },
              {
                id: 8,
                name: 'TUBULAR INSPECTIONS',
              },
              {
                id: 9,
                name: 'Rig acceptance survey audit',
              },
              {
                id: 10,
                name: 'HEAVY DUTY EQUIPMENT MAINTENANCE',
              },
              {
                id: 11,
                name: 'Welding & Fabrication',
              },
              {
                id: 12,
                name: 'Instrumentation and control',
              },
            ].map((item, index) => (
              <li
                className={`mb-3 font-serif  px-3 ${
                  activeTab === item.id ? 'bg-cyan-50 dark:bg-cyan-50/30' : ''
                } border-l-4   cursor-pointer  ${
                  activeTab === item.id ? 'border-cyan-400' : 'hover:border-gray-400'
                }`}
                onClick={(e) => handleTabClick(e, item.id)}
                key={index}
              >
                <h4
                  className={` lowercase text-lg dark:text-cyan-500 box-border font-serif font-semibold text-gray-700  ${
                    activeTab === item.id ? 'text-cyan-400' : ' '
                  }`}
                >
                  {item.name}
                </h4>
                <p className='text-base text-gray-600 dark:text-slate-400'>{item.text}</p>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <div>
            {[
              {
                id: 1,
                name: 'Pipeline inspection and survey',
                img: '/assets/b1.jpg',
                t2: 'Offshore Containers ships/vessels, Lifting equipments and  Accessories,Tanks and cargo basket,Heavy duty equipments/machines,Rig Tools,Magnetic particles inspection,Visual inspection,Dye penetration inspection',
              },

              {
                id: 2,
                name: 'ENGINEERING PROCUREMENTS',
                img: 'https://www.eunicenergy.com/wp-content/uploads/2020/10/AboutUs-scaled.jpg',
                t2: 'we offer comprehensive solutions to all your procurement needs',
              },

              {
                id: 3,
                name: 'LOGISTICS',
                img: 'https://www.cscc.edu/_resources/images/logistics-engineering-technology-main.jpg',
                t2: 'We are here for  all your engineering logistics needs',
              },

              {
                id: 4,
                name: 'CALIBRATION & CERTIFICATION SERVICES',
                img: 'https://www.obsnap.com/wp-content/uploads/2020/02/Certificate-of-Calibration.jpg',
                t2: 'Process equipment and and tank calibrations',
              },

              {
                id: 5,
                name: 'NON-DESTRUCTIVE TESTING',
                img: '/assets/b18.jpg',
                t2: 'Non-destructive testing on welded joints.Flaw detective testing on tanks welded joints',
              },

              {
                id: 6,
                name: 'PRESSURE TESTING AND LEAK TEST',
                img: 'https://atslab.com/wp-content/uploads/2021/08/Hydrostatic-Pressure-Testing.jpg',
                t2: 'We perform pressure tests',
              },

              {
                id: 7,
                name: 'PERSONNEL TRAINING',
                img: '/assets/v2.jpg',
                t2: 'Rigging and hoisting ,crane and forklift operation, Banksman & slinger training,person in charge training, instrumentation & control training, HSE training',
              },

              {
                id: 8,
                name: 'TUBULAR INSPECTIONS',
                img: 'https://www.petroliftiraq.com/imgs/tabular/2.jpg',
                t2: 'Lifting equipment and control systems consultation',
              },

              {
                id: 9,
                name: 'Rig acceptance survey audit',
                img: 'https://rigquip.com/wp-content/uploads/2019/04/ADNOC-Jack-Up-Rig-RigQuip.jpg',
                t2: 'We are the professionals to survey and auidit your rig',
              },

              {
                id: 10,
                name: 'Crane rapairs & other heavy duty equipment maintenance',
                img: 'https://www.panaceaengineers.in/products/crane-repair-maintenance-2.jpg',
                t2: 'Repair and maintenance of damaged Fiber Life boats.',
              },
              {
                id: 11,
                name: 'Welding & Fabrication',
                img: 'https://www.millerwelds.com/-/media/miller-electric/images/article-images/8/f/5/multimatic-220-ac-dc-application-0807180586_resized.jpg?h=410&w=720&la=en&hash=9DFB212CC7EB88B150B570C536DB799A',
                t2: 'Fabrication of skids and storage tanks,Modifications and redesigning',
              },
              {
                id: 12,
                name: 'Instrumentation and control',
                img: '/assets/b17.jpg',
                t2: 'Instrumentation and control of manufacturing/production systems',
              },
            ].map(
              (item, i) =>
                activeTab === item.id && (
                  <div className='font-serif' data-aos='zoom-in' key={i}>
                    <h3 className='text-xl text-cyan-400 pb-4 font-semibold uppercase'>
                      {item.name}
                    </h3>
                    <p className='text-gray-600 text-lg mb-4 dark:text-slate-400'>{item.t1}</p>
                    <div className='lg:flex gap-x-4'>
                      <img
                        className='object-cover object-center w-[350px] 0md:w-full mb-4 dark:mix-blend-darken '
                        src={item.img}
                        alt=''
                        loading='lazy'
                      />
                      <p className=' text-gray-600 text-lg box-border dark:text-slate-400'>
                        {item.t2}
                      </p>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Departments;
