import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BiMenuAltRight } from 'react-icons/bi';
import { GrClose } from 'react-icons/gr';

const Nav = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <div>
      <div className='flex justify-between items-center bg-white p-1 relative dark:bg-slate-900 '>
        <div className='flex items-center gap-x-1'>
          <Link to='/' className='border-r-8 border-blue-950 border-b-8'>
            <img src='favicon.png' alt='Cyn-blitz Logo' className='w-20 0md:w-14' />
          </Link>
          <div>
            <h1 className='dark:text-gray-300 xl:text-4xl text-2xl items-baseline font-bold text-blue-950 0md:hidden md:text-3xl'>
              Cyn-blitz global limited
            </h1>
          </div>
        </div>
        <div>
          {toggle && (
            <div className='lg:hidden bg-blue-950 p-6 absolute top-0 h-screen  right-0  w-1/2 transition-all animate-topdownAnimation z-[700] dark:bg-slate-900'>
              <div className='my-8'>
                <GrClose
                  className='lg:hidden text-2xl bg-white '
                  onClick={() => (toggle ? setToggle(false) : setToggle(true))}
                />
              </div>
              <div>
                {[
                  ['Home', '/'],
                  ['About', '/about'],
                  ['Services', '/services'],
                  ['Contact', '/contact'],
                ].map(([title, url]) => (
                  <Link
                    key={url}
                    to={url}
                    className='  rounded-lg px-3 py-2 text-white font-bold text-lg hover:bg-slate-400 hover:text-slate-900 block'
                    onClick={() => (toggle ? setToggle(false) : setToggle(true))}
                  >
                    {title}
                  </Link>
                ))}
              </div>
            </div>
          )}

          <div className='0sm:hidden lg:block'>
            {[
              ['Home', '/'],
              ['About', '/about'],
              ['Services', '/services'],
              ['Contact', '/contact'],
            ].map(([title, url]) => (
              <Link
                to={url}
                key={url}
                className='dark:text-gray-300 rounded-lg px-3 py-2 text-slate-700 font-bold hover:bg-slate-100 dark:hover:bg-yellow-600 hover:text-slate-900 text-xl'
              >
                {title}
              </Link>
            ))}
          </div>
          <div>
            <BiMenuAltRight
              className='lg:hidden text-4xl dark:text-gray-200'
              onClick={() => (toggle ? setToggle(false) : setToggle(true))}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
